import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

// export const formatDate = (inputDate: any) => {
//   if (inputDate) {
//     const dateObject = new Date(inputDate);
//     const month = String(dateObject.getMonth() + 1).padStart(2, '0');
//     const day = String(dateObject.getDate()).padStart(2, '0');
//     const year = dateObject.getFullYear();
//     return `${month}/${day}/${year}`;
//   }
//   return '';
// }

// export const formatDate = (inputDate: any) => {
//   if (inputDate) {
//     // Create a new Date object using the inputDate, assuming it is in ISO 8601 format
//     const dateObject = new Date(inputDate);

//     // Get the date parts in UTC to avoid time zone issues
//     const month = String(dateObject.getUTCMonth() + 1).padStart(2, '0');
//     const day = String(dateObject.getUTCDate()).padStart(2, '0');
//     const year = dateObject.getUTCFullYear();

//     return `${month}/${day}/${year}`;
//   }
//   return '';
// }

export const formatDate = (inputDate: any) => {
  if (inputDate) {
    const dateObject = new Date(inputDate);

    return new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York', // UTC
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).format(dateObject);
  }
  return '';
}


export const formatTime = (inputDate: any) => {
  if (inputDate) {
    const dateObject = new Date(inputDate);
    let hours = dateObject.getHours();
    const mins = String(dateObject.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    const hoursStr = String(hours).padStart(2, '0');
    return `${hoursStr}:${mins} ${ampm}`;
  }
  return '';
}

export const formatDateTime = (inputDate: any) => `${formatDate(inputDate)} ${formatTime(inputDate)}`;
